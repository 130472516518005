import * as React from "react";
import {
  JumbotronSubTitle,
  JumbotronTexts,
  JumbotronTitle,
  JumbotronWrapper,
} from "./_mainComponents";
import { useIntl } from "gatsby-plugin-intl";
import { GatsbyImage } from "gatsby-plugin-image";

const Jumbotron = ({ imageQuery }: any) => {
  const intl = useIntl();

  return (
    <JumbotronWrapper>
      <JumbotronTexts>
        <JumbotronTitle className="bigducks">
          {intl.formatMessage({ id: "ducks" })}
        </JumbotronTitle>
        <JumbotronSubTitle className="smallducks">
          {intl.formatMessage({ id: "ducksSmall" })}
        </JumbotronSubTitle>
      </JumbotronTexts>
      <GatsbyImage
        image={imageQuery.edges[0].node.childImageSharp.gatsbyImageData}
        alt={"t"}
        style={{ zIndex: 30 }}
      />
    </JumbotronWrapper>
  );
};

export default Jumbotron;
