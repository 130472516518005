import * as React from "react";
import { FloatingLine, FloatingTexts } from "./_mainComponents";
import { useEffect } from "react";

const lines = [
  "react.js",
  "graphql",
  "angular",
  "typescript",
  "javascript",
  "nodejs",
  "express.io",
  "strapi.io",
  "websockets",
  "rest",
  "signalr",
  "c#",
  "c++",
  "python",
  "embedded",
  "raspberrypi",
  "backend",
  "frontend",
  "css",
  "html",
  "UX",
  "UI",
  "design",
  "film",
  "photography",
  "java",
  "spring",
  "php",
  "mysql",
  "mssql",
  "postgresql",
  "blockchain",
  "ganache",
  "truffle",
  "ethereum",
  "web3",
  "web2.0",
  "webapi",
  "gatsby",
  "mongodb",
  "neo4j",
  "database",
  "relational database",
  "graph database",
  "performance",
  "optimization",
  "algorithms",
  "cryptography",
  "networking",
  "linux",
  "gnu",
  "windows",
  "mac os x",
  "terminal",
  "git",
  "webdev",
  "responsive",
  "rwd",
  "mobile",
  "ios",
  "android",
  "redux",
  "mobx",
  "state",
  "state machine"
];

function random(x: number, y: number) {
  return Math.floor(Math.random() * y) + x;
}

const WhoamiBackgroundTexts = () => {
  useEffect(() => {
    const main = document.getElementsByClassName("mainWrapper");
    const amountOffloats = 8;
    let floats: HTMLDivElement[] = [];
    for (let i = 0; i <= amountOffloats; i++) {
      floats.push(document.getElementsByClassName(
        `float${i}`
      )[0] as HTMLDivElement);
    }
    floats.forEach((float, index) => {
        let t = "";
      for (let w = 0; w <= 200; w++) {
        t += ` ${lines[random(0, lines.length - 1)]} `;
      }
      float.innerText = t;
    });

    const width = floats[0].clientWidth * 2;
    main[0].addEventListener("scroll", e => {
      // @ts-ignore
      const k = e.target.scrollTop / 10;
      floats.forEach((float, index) => {
        float.style.transform = `translateX(${
          index % 2 ? (index % 3 ? -width + k : -width + k * 4) : -width - k *2
        }px)`;
      });
    });
  }, []);

  return (
    <FloatingTexts>
      <FloatingLine className="float0"></FloatingLine>
      <FloatingLine className="float1"></FloatingLine>
      <FloatingLine className="float2"></FloatingLine>
      <FloatingLine className="float3"></FloatingLine>
      <FloatingLine className="float4"></FloatingLine>
      <FloatingLine className="float5"></FloatingLine>
      <FloatingLine className="float6"></FloatingLine>
      <FloatingLine className="float7"></FloatingLine>
      <FloatingLine className="float8"></FloatingLine>
    </FloatingTexts>
  );
};

export default WhoamiBackgroundTexts;
