import * as React from "react";
import "../styles/normalize.css";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import Header from "../components/Header";
import Jumbotron from "../components/Jumbotron";
import Whoami from "../components/Whoami";
import {HelmetComponent, Layout} from "../components/Layout";
import { MainSiteWrapper } from "../components/_mainComponents";
// @ts-ignore
import Rellax from "rellax";
import {useEffect, useRef, useState} from "react";
import {graphql} from "gatsby";

const Main = ({data} : any) => {
  const intl = useIntl();
  const mainPage = useRef();

  useEffect(() => {
    new Rellax(".bigducks", {
      speed: -3,
      wrapper: ".mainWrapper"
    });
    new Rellax(".smallducks", {
      speed: -1,
      wrapper: ".mainWrapper"
    });
    new Rellax(".whoami", {
      speed: -2,
      wrapper: ".mainWrapper"
    });
  }, []);


  return (
    <Layout>
      <Jumbotron imageQuery={data.allFile}/>
      <Whoami />
    </Layout>
  );
};

export const ImageQuery = graphql`
  query StaraKacza {
    allFile(filter: {extension: {regex: "/(png)/"},dir: {regex: "/src/images/"}, name: {regex: "/^testkaczki2/"}}) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
  }
`

export default Main;
