import * as React from 'react';
import { WhoamiTitle, WhoamiWrapper} from "./_mainComponents";
import WhoamiBackgroundTexts from "./WhoamiBackgroundTexts";

const Whoami = () => {
    return (
        <WhoamiWrapper>
            <WhoamiTitle className="whoami">WHOAMI?</WhoamiTitle>
            <WhoamiBackgroundTexts/>
        </WhoamiWrapper>
    );
};

export default Whoami;
